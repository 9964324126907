.shadow-factory(@type, @offset-percent) when (@type = "red") {
    text-shadow: calc(var(--nocp-broken-offset) * @offset-percent) calc(var(--nocp-broken-offset) * @offset-percent) var(--nocp-broken-red);
}

.shadow-factory(@type, @offset-percent) when (@type = "blue") {
    text-shadow: calc(var(--nocp-broken-offset) * -@offset-percent) calc(var(--nocp-broken-offset) * -@offset-percent) var(--nocp-broken-blue);
}

@keyframes broken-red {
    0%, 10%, 25%, 35%, 100% {
        .shadow-factory("red", 0);
    }
    5%, 30% {
        .shadow-factory("red", 1);
    }
}

@keyframes broken-blue {
    0%, 10%, 25%, 35%, 100% {
        .shadow-factory("blue", 0);
    }
    5%, 30% {
        .shadow-factory("blue", 1);
    }
}

@keyframes broken-text {
    from {
        height: 0;
    }
    to {
        height: 100%;
    }
}
